import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LuEye, LuEyeOff } from 'react-icons/lu';

interface LoginFormProps {
  setUserForm: React.Dispatch<
    React.SetStateAction<{
      id: string;
      password: string;
    }>
  >;
  errorMessage: string;
  handleLoginClick: () => void;
}

function LoginForm({
  setUserForm,
  errorMessage,
  handleLoginClick,
}: LoginFormProps) {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const handleChangeForm = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'password' | 'id',
  ) => {
    if (type === 'password')
      setUserForm((prev) => ({
        ...prev,
        password: e.target.value,
      }));
    else if (type === 'id')
      setUserForm((prev) => ({
        ...prev,
        id: e.target.value,
      }));
  };

  const handleClickPasswordVisibility = () => {
    setPasswordVisibility((prev) => !prev);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLoginClick();
    }
  };

  return (
    <form className="md:w-[30.25rem] w-[16rem] h-[16rem] flex flex-col justify-between mx-auto">
      <input
        className="md:w-[30.25rem] w-[16rem] mx-auto mb-2 text-lg font-cantarell font-bold bg-white px-2 py-[0.8rem] border-2 border-gray-300 rounded-lg transition-all duration-300 ease-in-out focus:outline-none focus:border-hpBlue"
        type="text"
        placeholder="아이디(전화번호)"
        onChange={(e) => {
          handleChangeForm(e, 'id');
        }}
        onKeyDown={handleKeyDown} // Enter 키 감지
      />
      <div className="md:w-[30.25rem] w-[16rem] relative">
        <input
          className="md:w-[30.25rem] w-[16rem] mx-auto text-lg font-cantarell font-bold bg-white px-2 py-[0.8rem] border-2 border-gray-300 rounded-lg transition-all duration-300 ease-in-out focus:outline-none focus:border-hpBlue"
          type={passwordVisibility ? 'text' : 'password'}
          placeholder="비밀번호"
          autoComplete="true"
          onChange={(e) => {
            handleChangeForm(e, 'password');
          }}
          onKeyDown={handleKeyDown} // Enter 키 감지
        />
        {passwordVisibility ? (
          <button
            className="absolute right-4 top-4 transition-transform transform hover:scale-110"
            type="button"
            aria-label="비밀번호 보기"
            onClick={handleClickPasswordVisibility}
          >
            <LuEye size="1.5rem" color="#BCBCBC" />
          </button>
        ) : (
          <button
            className="absolute right-4 top-4 transition-transform transform hover:scale-110"
            type="button"
            aria-label="비밀번호 보이지 않기"
            onClick={handleClickPasswordVisibility}
          >
            <LuEyeOff size="1.5rem" color="#BCBCBC" />
          </button>
        )}
      </div>
      <div className="md:w-[30.25rem] w-[16rem] h-[5rem] pb-6 relative flex items-center">
        {errorMessage !== '' && (
          <div className="md:w-[30.25rem] w-[16rem] h-[2.5rem] absolute md:bottom-4 bottom-8 text-center leading-[2.5rem]">
            <span className="font-bold text-hpRed text-lg">{errorMessage}</span>
          </div>
        )}
      </div>
      <div
        className={`md:w-[30.25rem] w-[16rem] h-[3.875rem] rounded-xl mx-auto transition-all duration-300 ease-in-out ${
          errorMessage !== ''
            ? 'bg-hpGray '
            : 'bg-hpBlue hover:bg-hpDarkBlue hover:scale-105'
        }`}
      >
        <button
          className="md:w-[30.25rem] w-[16rem] h-[3.875rem] transition-all duration-300 ease-in-out"
          type="button"
          onClick={handleLoginClick}
          disabled={errorMessage !== ''}
        >
          <span className="text-md text-white font-bold">로그인</span>
        </button>
      </div>
    </form>
  );
}

LoginForm.propTypes = {
  setUserForm: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleLoginClick: PropTypes.func.isRequired,
};

export default LoginForm;
